* {
  margin: 0 10px;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--tg-theme-text-color);
  background: var(--tg-theme-bg-color);
  button_color: var(--tg-theme-button-color);
  button_text_color: var(--tg-theme-button-text-color);
}
button {
  display: block;
  width: 100%;
  font-size: 14px;
  margin: 15px 0;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  background-color: var(--tg-theme-button-color, #50a8eb);
  color: var(--tg-theme-button-text-color, #ffffff);
  cursor: pointer;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
